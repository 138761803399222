import axios from "@axios";

export default {
    namespaced: true,
    state: {
        plans: [],
        subscription: null,
    },
     getters : {
        currentSubscription(state) {
            return state.subscription
        },

        isSubscriptionActive(state) {
            return state.subscription && state.subscription.stripe_status === 'active' || state.subscription && state.subscription.stripe_status === 'trailing'
        }
    },
    mutations: {
        setPlans(state, payload) {
            state.plans = payload.map(plan => {
                // Modify the description
                plan.description = plan.description.split(',').map(item => {
                  const words = item.trim().split(' ');
                  for (let i = 0; i < words.length; i++) {
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                  }
                  return words.join(' ');
                });
          
                // Modify the price
                plan.price = parseFloat(plan.price).toLocaleString('en-US', { style: 'decimal' });
          
                return plan;
            });
        },
        setSubscription(state, payload) {
            state.subscription = payload;
        },
    },
    actions: {
        async fetchPlans({ commit }, _params) {
            try {
                const result = await axios.get("/v1/stripe/plans");
                await commit("setPlans", result.data);
            } catch (error) {
                console.log(error);
            }
        },
        fetchSubscription({ commit }, establisment_id) {
            return new Promise((resolve, reject) => {
                axios
                  .get(`/v1/stripe/subscription/${establisment_id}`)
                  .then(response => {
                    commit("setSubscription", response.data);
                    resolve(response.data)
                  })
                  .catch(error => {
                    reject(error)
                  })
            })
          
        },
    },
};
