import axios from '@axios'

export default {
  namespaced: true,
  state: {
    store_accounts: {},
    establishmentAccounts: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    }
  },
  getters: {
    store_accounts(state) {
      return state.store_accounts
    },
    storeAccount(state) {
      return state.store_accounts.data
    },
    establishmentAccounts(state) {
      return state.establishmentAccounts.accounts
    },
    establishmentAccountsPagination(state) {
      return state.establishmentAccounts.meta.pagination
    }
  },
  mutations: {
    set_store_accounts(state, payload) {
      state.store_accounts = payload
    },
    setStoreAccount(state, payload){
      state.store_accounts.data = payload
    },
    setEstablishmentAccounts(state, payload) {
      state.establishmentAccounts = payload
    },
  },
  actions: {
    fetchStoreAccounts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/store_accounts', {
            params,
          })
          .then(response => {
            ctx.commit('set_store_accounts', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchStoreAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/stripe/store_accounts/${params.id}`)
          .then(response => {
            ctx.commit('setStoreAccount', response.data.store_account)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createEstablishmentAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/stripe/account', params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createStoreAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/stripe/store_account', params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    completeOnboarding(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/stripe/account_link/${params.establishment_id}/${params.stripe_account_id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEstablishmentAccountLink(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/stripe/account_link/${params.establishment_id}/${params.stripe_account_id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEstablishmentAccounts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/stripe/accounts/${params.establishment_id}`, {
            params,
          })
          .then(response => { 
            ctx.commit('setEstablishmentAccounts', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    checkoutBankTransfer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/stripe/checkout_account/bank_transfer`, params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    checkoutBankCard(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/stripe/checkout_account/bank_card`, params)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateEstablishmentStripeAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/stripe/account/${params.establishment_id}/${params.stripe_account_id}`, {
            establishment_account: {
              alias: params.alias,
              active_status: params.active_status,
            }
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
