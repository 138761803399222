import axios from "@axios"

export default {
  namespaced: true,
  state: {
    currentStore: null,
    currentStoreAcceptedPaymentTypes: [],
    currentStoreProducts: [],
    currentStoreCategories: [],
    storeForLoanPayment: null,
    productsShown: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
    historicalInventoryDates: null,
    historicalInventoryValues: null,
    dailyResume: {
      current_day_sales_and_margins_report: {
        daily_sales: 0,
        monthly_sales: 0,
        daily_margins: 0,
        monthly_margins: 0,
        current_day_total_orders_count: 0,
        current_month_avg_ticket: 0,
      },
      current_day_sales_report: {
        cash_sales: 0,
        walleat_sales: 0,
        bank_card_sales: 0,
        total_sales: 0,
      },
      current_day_cash_report: {
        cash_security_withdrawal: 0,
        cash_payments_to_suppliers: 0,
        cash_in_register: 0,
      },
      current_day_inventory_report: {
        current_inventory_value_at_current_price: 0,
        current_inventory_margin: 0,
        current_month_inventory_purchases: 0,
        current_month_inventory_lost_at_cost: 0,
        total_inventory_lost_at_cost: 0,
        total_store_suppliers_loans_balance: 0,
      },
    },
    onlineStores: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
    stores: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
  },
  getters: {
    currentStore(state) {
      return state.currentStore
    },
    getStoreIdForLoanPayment(state) {
      return state.storeForLoanPayment
    },
    currentStoreProducts(state) {
      return state.currentStoreProducts
    },
    currentStoreCategories(state) {
      return state.currentStoreCategories
    },
    dailyResume(state) {
      return state.dailyResume
    },
    productsShown(state) {
      return state.productsShown.data
    },
    pagination(state) {
      return state.productsShown.meta.pagination
    },
    historicalInventoryDates(state) {
      return state.historicalInventoryDates
    },
    historicalInventoryValues(state) {
      return state.historicalInventoryValues
    },
    onlineStores(state) {
      return state.onlineStores.data
    },
    stores(state) {
      return state.stores.data
    },
    onlineStoresPagination(state) {
      return state.onlineStores.meta.pagination
    },
    currentStoreAcceptedPaymentTypes(state) {
      return state.currentStoreAcceptedPaymentTypes
    },
  },
  mutations: {
    setCurrentStore(state, payload) {
      state.currentStore = payload
    },
    setOnlineStores(state, payload) {
      state.onlineStores = payload
    },
    setStores(state, payload) {
      state.stores = payload
    },
    setStoreIdForLoanPayment(state, payload) {
      state.storeForLoanPayment = payload
    },
    setDailyResume(state, payload) {
      state.dailyResume = payload
    },
    setProductsShown(state, payload) {
      state.productsShown = payload
    },
    setCurrentStoreProducts(state, payload) {
      state.currentStoreProducts = payload
    },
    setCurrentStoreCategories(state, payload) {
      state.currentStoreCategories = payload
    },
    setCurrentStoreAcceptedPaymentTypes(state, payload) {
      state.currentStoreAcceptedPaymentTypes = payload
    },
    setSupplierProducts(state, payload) {
      const originalArray = payload.data

      // Create an object to store the grouped elements
      const groupedProducts = {}
      let needs_attention = 1

      // Iterate through the original array
      originalArray.forEach((element) => {
        const productId = element.product_attributes.id

        // If the product ID is not in the groupedProducts object, create an entry for it
        if (!groupedProducts[productId]) {
          groupedProducts[productId] = {
            ...element.product_attributes,
            store_products_attributes: [],
          }
        }

        // Add the store information to the grouped product
        groupedProducts[productId].store_products_attributes.push({
          id: element.id,
          inventory: element.inventory,
          historical_inventory: element.historical_inventory,
          created_at: element.created_at,
          updated_at: element.updated_at,
          store_attributes: element.store_attributes,
        })
      })

      // Convert the groupedProducts object into an array
      const groupedArray = Object.values(groupedProducts)

      // Create new variable needs attention
      groupedArray.forEach((product) => {
        let needs_attention = 2
        product.store_products_attributes.forEach((store_product) => {
          if (store_product.inventory > 5 && store_product.inventory <= 10) {
            needs_attention = 1
          } else if (store_product.inventory < 5) {
            needs_attention = 0
          }
        })
        product.needs_attention = needs_attention
      })

      payload.data = groupedArray
      state.productsShown = payload
    },
    setHistoricalInventoryDates(state, payload) {
      state.historicalInventoryDates = payload
    },
    setHistoricalInventoryValues(state, payload) {
      state.historicalInventoryValues = payload
    },
  },
  actions: {
    fetchStoreTypes(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/stores/get_store_types", {
            params,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchOnlineStores(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/stores/online_stores", {
            params: {
              ...params,
            },
          })
          .then((response) => {
            ctx.commit("setOnlineStores", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchStores(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/stores", {
            params,
          })
          .then((response) => {
            ctx.commit("setStores", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchStore(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/stores/${id}`)
          .then((response) => {
            ctx.commit("setCurrentStore", response.data)
            ctx.commit(
              "setCurrentStoreAcceptedPaymentTypes",
              Object.values(response.data.accepted_payment_types)
            )
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchStoreProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/store_products", { params })
          .then((response) => {
            ctx.commit("setProductsShown", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchSupplierProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/store_products/supplier_products", { params })
          .then((response) => {
            ctx.commit("setSupplierProducts", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchOnlineStoreProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/store_products/online_products", { params })
          .then((response) => {
            ctx.commit("setProductsShown", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addStore(ctx, store) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/stores/", {
            store,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editStore(ctx, { id, store }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/stores/${id}`, {
            store,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    openStore(context, { storeId, cash }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/open_days", {
            open_day: {
              store_id: storeId,
              cash_inicial: cash,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    closeStore(context, { storeId, cash }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/open_days/${storeId}`, {
            open_day: {
              cash_final: cash,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    cashWithdrawal(context, { storeId, cash }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/open_days/cash_withdrawal?id=${storeId}`, {
            open_day: {
              cash_security_withdrawal: cash,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchDashboard(context, { storeId, frequency }) {
      return new Promise((resolve, reject) => {
        let url = `/v1/dashboard?frequency=${frequency}` // URL without store_id
        if (storeId) {
          url += `&store_id=${storeId}` // Append store_id if provided
        }
        axios
          .get(url)
          .then((response) => {
            context.commit("setDailyResume", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchReferralToken(context, { storeId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/stores/${storeId}/generate_referral_token`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
