export default [
  {
    path: '/POS1/:store_id',
    name: 'POS1',
    component: () => import('@/views/e-commerce/e-commerce-checkout/Pos.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/POS/:store_id',
    name: 'POS',
    component: () => import('@/views/pos/Pos2.vue'),
    meta: {
      layout: 'full',
      requiresAuth: true,
    },
  },
  {
    path: '/selectPOS',
    name: 'selectPOS',
    component: () => import('@/views/e-commerce/e-commerce-checkout/SelectPOS.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/testPos/:store_id',
    name: 'testPos',
    component: () => import('@/views/test/test.vue'),
    meta: {
      requiresAuth: true,
    },
  },

]
